import React, { useCallback, useEffect, useState } from 'react';
import Select from '../../Select';
import { COLOR_TREE, DESTINATION_TYPE } from '../const';
import DestinationsService from '../../../services/destinationsService';
import StorageService from '../../../services/storage.service';
import { mapperDestinationTree } from '../helper';

const destinationsService = DestinationsService();
const storageService = StorageService();

function District({
  cityId,
  setDistrict,
  isLastItem = false,
}) {
  const [districtList, setDistrictList] = useState([]);

  const loadOptionsDistrictsList = useCallback(() => {
    if (cityId) {
      setDistrictList([]);
      destinationsService.getDistricts(cityId)
        .then((data) => {
          const updatedList = mapperDestinationTree(data);
          storageService.set(DESTINATION_TYPE.district, updatedList);
          return updatedList;
        })
        .then((updatedList) => {
          setDistrictList(updatedList);
        });
    }
  }, [setDistrictList, cityId]);

  useEffect(() => {
    loadOptionsDistrictsList();
  }, [cityId]);

  return (
    <Select
      name="district"
      placeholder="Select a District/Level 4 (optional)"
      loadOption={loadOptionsDistrictsList}
      defaultOptions={districtList}
      color={COLOR_TREE.district}
      valuePrefix="District: "
      widthContainer="85%"
      setValueState={setDistrict}
      isBoldStyle={isLastItem}
      withDestination
    />
  );
}

export default District;
