import React, { useCallback, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useField } from 'formik';
import { components } from 'react-select';
import { noop } from '../../helpers/helper-methods';
import { getValueForDestination } from '../DestinationTree/helper';
import IconTick from '../../assets/img/icons/icon-tick.svg';

import styles from './index.module.scss';

function Select({
  valuePrefix = 'Country: ',
  placeholder = '',
  name = '',
  loadOption = noop,
  defaultOptions = [],
  color = '#2684FF',
  widthContainer = '100%',
  setValueState = noop,
  isBoldStyle = false,
  afterChange = noop,
  withDestination = false,
}) {
  const [field, , { setValue }] = useField({
    name,
    placeholder,
  });
  const handleChange = useCallback((selectedOption) => {
    setValue(selectedOption);
    setValueState(getValueForDestination(selectedOption));
    afterChange();
  }, [setValue, setValueState]);

  useEffect(() => {
    if (field.value) {
      setValueState(getValueForDestination(field.value));
    }
  }, [field.value]);

  const customStyles = {
    menu: () => ({
      position: 'absolute',
      border: `1px solid ${withDestination && !field.value ? '#ddd' : color}`,
      borderRadius: '8px',
      background: 'white',
      width: '100%',
      cursor: 'pointer',
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: '200px',
      borderRadius: '8px',
      background: 'white',
      display: 'block',
      zIndex: '2',
      cursor: 'pointer',
    }),
    control: () => ({
      border: `1px solid ${withDestination && !field.value ? '#fff' : color}`,
      borderRadius: '8px',
      height: '40px',
      display: 'flex',
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? '#02AAFF' : '#2B2B2B',
      background: state.isSelected ? 'white' : 'white',
      ':hover': {
        backgroundColor: state.isSelected ? 'white' : 'rgba(2, 170, 255, 0.05)',
      },
      cursor: 'pointer',
    }),
    container: () => ({
      width: widthContainer,
      position: 'relative',
    }),

  };

  return (
    <AsyncSelect
      name={name}
      value={field.value}
      isClearable={false}
      isSearchable={false}
      placeholder={placeholder}
      loadOption={loadOption}
      defaultOptions={defaultOptions}
      onChange={handleChange}
      styles={customStyles}
      className={styles.select}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        SingleValue: ({
          children,
          ...props
        }) => (
          <components.SingleValue {...props}>
            <span className={styles.prefixPart}>{withDestination && !field.value ? '' : valuePrefix}</span>
            <span className={isBoldStyle ? styles.boldItem : styles.normalItem}>{children}</span>
          </components.SingleValue>
        ),
        Placeholder: ({
          children,
          ...props
        }) => (
          <components.Placeholder {...props}>
            <span className={styles.prefixPart}>{withDestination && !field.value ? '' : valuePrefix}</span>
            <span className={styles.placeholder}>{children}</span>
          </components.Placeholder>
        ),
        Option: ({
          children,
          isSelected,
          ...props
        }) => (
          <components.Option {...props} styles={customStyles.option}>
            {isSelected && <span className={styles.tickIcon}><IconTick /></span>}
            <span className={isSelected ? styles.selectedOption : styles.defaultOption}>
              {children}
            </span>
          </components.Option>
        ),
      }}
    />
  );
}

export default Select;
