import React, { useCallback, useEffect, useState } from 'react';
import Select from '../../Select';
import { COLOR_TREE } from '../const';
import DestinationsService from '../../../services/destinationsService';
import { mapperDestinationTree } from '../helper';

const destinationsService = DestinationsService();

function Country({
  setCountry,
  isLastItem = false,
  afterChange = () => {},
}) {
  const [countryList, setCountryList] = useState([]);

  const loadOptionsCountriesList = useCallback(() => {
    setCountryList([]);

    destinationsService.getCountries()
      .then(mapperDestinationTree)
      .then((updatedList) => {
        setCountryList(updatedList);
      });
  }, [setCountryList]);

  useEffect(() => {
    loadOptionsCountriesList();
  }, []);

  return (
    <Select
      name="country"
      placeholder="Select a Country"
      loadOption={loadOptionsCountriesList}
      defaultOptions={countryList}
      color={COLOR_TREE.country}
      setValueState={setCountry}
      isBoldStyle={isLastItem}
      afterChange={afterChange}
      withDestination
    />
  );
}

export default Country;
