import React, {
  useCallback, useState, useMemo,
} from 'react';
import { navigate } from 'gatsby';
import qs from 'qs';
import { Formik } from 'formik';
import { object, string } from 'yup';

import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import { getDestinationName, noop } from '../../helpers/helper-methods';
import SearchInputAutocomplete
  from '../Navigation/NavigationSearch/SearchInputAutocomplete/SearchInputAutocomplete';

import styles from './index.module.scss';
import stylesSearch from '../Navigation/NavigationSearch/NavigationSearch.module.scss';
import IconMarker from '../../assets/img/icons/ic-marker_region.svg';
import Region from './Region';
import Country from './Country';
import City from './City';
import District from './District';
import { filterDestinationsAllValues, transformSelectedValue } from './helper';
import destinationsService from '../../services/destinationsService';
import StorageService from '../../services/storage.service';
import { DESTINATION_TYPE } from './const';

const schema = object()
  .shape({
    selectedName: string()
      .required(),
  });

const storageService = StorageService();

export const STORAGE_DESTINATION = 'destination';

function DestinationTree({
  isOpen,
  selected,
  onCloseClick,
  onShowClick,
  withSelect,
  onSelect,
  isRegionRequired,
  destinationModalClasses,
}) {
  const [country, setCountry] = useState(selected?.country);
  const [region, setRegion] = useState(selected?.region);
  const [city, setCity] = useState(selected?.city);
  const [district, setDistrict] = useState(selected?.district);
  const [searchResults, setSearchResults] = useState([]);

  let resetValues = noop;
  let updateFieldValue = noop;

  const getRedirectQuery = useCallback(() => qs.stringify({
    countryId: country?.id,
    regionId: region?.id,
    cityId: city?.id,
    districtId: district?.id,
    name: getDestinationName(district, city, region, country),
  }), [country, region, city, district]);

  const handleCountryClick = useCallback(() => {
    updateFieldValue('region', null);
    updateFieldValue('city', null);
    updateFieldValue('district', null);
    setRegion();
    setCity();
    setDistrict();
  }, [updateFieldValue, setRegion, setCity, setDistrict]);

  const handleRegionClick = useCallback(() => {
    updateFieldValue('city', null);
    updateFieldValue('district', null);
    setCity();
    setDistrict();
  }, [updateFieldValue, setCity, setDistrict]);

  const handleCityClick = useCallback(() => {
    updateFieldValue('district', null);
    setDistrict();
  }, [updateFieldValue, setDistrict]);

  const handleSelect = () => {
    const currentDestination = {
      country,
      region,
      city,
      district,
      parentsNames: [district?.name, city?.name, region?.name, country?.name]
        .filter((value) => !!value)
        .splice(1)
        .join(', '),
      name: district?.name || city?.name || region?.name || country?.name,
    };

    onSelect(currentDestination);

    storageService.set(STORAGE_DESTINATION, currentDestination);

    onCloseClick();
  };

  const handleShowActivities = () => {
    if (onShowClick()) {
      onShowClick();
    }

    navigate(`/activities/results?${getRedirectQuery()}`);
  };

  const handleShowProperties = () => {
    if (onShowClick()) {
      onShowClick();
    }

    navigate(`/properties/results?${getRedirectQuery()}`);
  };

  const handleSearchSubmit = useCallback(({ selectedName }, { setFieldValue }) => {
    if (searchResults.length) {
      const foundElement = {
        ...searchResults.find(({ name, region }) => name === selectedName
          || region?.name === selectedName
          || region?.city?.name === selectedName
          || region?.city?.district?.name === selectedName),
      };
      if (foundElement) {
        setCountry(foundElement);
        setRegion(foundElement?.region);
        setCity(foundElement?.region?.city);
        setDistrict(foundElement?.region?.city?.district);

        setFieldValue('country', foundElement?.id ? transformSelectedValue(foundElement) : null);
        setFieldValue('region', foundElement?.region ? transformSelectedValue(foundElement?.region) : null);
        setFieldValue('city', foundElement?.region?.city ? transformSelectedValue(foundElement?.region?.city) : null);
        setFieldValue('district', foundElement?.region?.city?.district ? transformSelectedValue(foundElement?.region?.city?.district) : null);
      }
    }
  }, [searchResults, setCountry, setRegion, setCity, setDistrict]);

  const handleSearch = async (searchValue) => {
    if (searchValue?.trim()?.length) {
      const result = await destinationsService()
        .getDestinations({
          searchString: searchValue,
        });
      const filteredData = filterDestinationsAllValues(result.data);
      setSearchResults(filteredData);
      return result;
    }
  };

  const handleResetAll = useCallback(() => {
    resetValues();
    updateFieldValue('country', null);
    updateFieldValue('region', null);
    updateFieldValue('city', null);
    updateFieldValue('district', null);
    setCountry();
    setRegion();
    setCity();
    setDistrict();
    onSelect({});
    storageService.remove(STORAGE_DESTINATION);
    storageService.remove(DESTINATION_TYPE.city);
    storageService.remove(DESTINATION_TYPE.district);
  }, [resetValues, setCountry, setRegion, setCity, setDistrict, onSelect, storageService]);

  const initial = useMemo(() => ({
    name: '',
    selectedName: '',
    country: storageService.get(STORAGE_DESTINATION)?.country?.id
      ? transformSelectedValue(storageService.get(STORAGE_DESTINATION)?.country)
      : transformSelectedValue(selected?.country) || null,
    region: storageService.get(STORAGE_DESTINATION)?.region?.id
      ? transformSelectedValue(storageService.get(STORAGE_DESTINATION)?.region)
      : transformSelectedValue(selected?.region) || null,
    city: storageService.get(STORAGE_DESTINATION)?.city?.id
      ? transformSelectedValue(storageService.get(STORAGE_DESTINATION)?.city)
      : transformSelectedValue(selected?.city) || null,
    district: storageService.get(STORAGE_DESTINATION)?.district?.id
      ? transformSelectedValue(storageService.get(STORAGE_DESTINATION)?.district)
      : transformSelectedValue(selected?.district) || null,
  }), [storageService, selected]);
  return (
    <Modal
      showClose
      displayToggle={onCloseClick}
      show={isOpen}
      modalClasses={`${styles.modal} ${styles.destinationModal} ${destinationModalClasses}`}
      closeStyles={styles.modalClose}
    >
      <div className={`d-flex flex-row justify-content-between align-content-between ${styles.baseWrapper}`}>
        <div className={`${styles.destinations}`}>
          <Formik
            onSubmit={handleSearchSubmit}
            validationSchema={schema}
            initialValues={initial}
          >
            {({
              setFieldValue,
              submitForm,
              values,
              resetForm,
            }) => {
              resetValues = resetForm;
              updateFieldValue = setFieldValue;
              return (
                <div className={`${stylesSearch.formDestination} w-100`}>
                  <SearchInputAutocomplete
                    isDestinationTree
                    isMobile={false}
                    submitForm={submitForm}
                    inputInitialValue={values.name}
                    onChange={handleSearch}
                    onSelect={(v) => setFieldValue('selectedName', v)}
                    wrapperClassName={`position-relative mb-0 ${stylesSearch.activityFormInput}`}
                    placeholder="Search destination"
                    onFocus={() => setFieldValue('selectedName', '')}
                    getResultValue={(v) => v.title}
                    setFieldValue={setFieldValue}
                    dropdownStyles={stylesSearch.activityDropdown}
                    dropdownItemTextStyles={stylesSearch.activityItem}
                    name="name"
                    prefixActivityIcon={
                      <IconMarker className={stylesSearch.icon} />
                    }
                    prefixPropertyIcon={
                      <IconMarker className={`${stylesSearch.icon} ${stylesSearch.iconProperty}`} />
                    }
                    selectedName={values?.selectedName}
                  />
                  <div className={`${styles.selectWrapper}`}>
                    <Country
                      setCountry={setCountry}
                      isLastItem={!values?.region?.value}
                      afterChange={handleCountryClick}
                    />
                  </div>
                  {values.country?.value && (
                    <div className={`${styles.selectWrapperOther}`}>
                      <Region
                        countryId={values.country.value}
                        setRegion={setRegion}
                        isLastItem={!values?.city?.value}
                        afterChange={handleRegionClick}
                      />
                    </div>
                  )}
                  {values.region?.value && (
                    <div className={`${styles.selectWrapperOther}`}>
                      <City
                        regionId={values.region.value}
                        setCity={setCity}
                        isLastItem={!values?.district?.value}
                        afterChange={handleCityClick}
                      />
                    </div>
                  )}
                  {values.city?.value && (
                    <div className={`${styles.selectWrapperOther}`}>
                      <District
                        cityId={values.city.value}
                        setDistrict={setDistrict}
                        isLastItem={values?.district?.value}
                      />
                    </div>
                  )}
                </div>
              );
            }}
          </Formik>
        </div>
        <div className="fixed-bottom">
          {withSelect ? (
            <div className={styles.actionBlock}>
              <div className="flex align-content-center">
                <Button
                  classes={`t-600 btn btn-uppercased btn_mld ${styles.reset}`}
                  onClick={handleResetAll}
                >
                  Reset all
                </Button>
              </div>

              <div className={styles.selectedDestination}>
                <div className={`${styles.selectedDestination} ${styles.confirmBlock}`}>
                  <p className={styles.selectedDestinationBlock}>
                    Selected destination:
                  </p>
                  <h4 className={styles.selectedDestinationText}>
                    {district?.name || city?.name || region?.name || country?.name}
                  </h4>
                </div>

                <div>
                  <Button
                    classes={`t-600 btn btn-uppercased btn_mld btn_common ${styles.select}`}
                    disabled={isRegionRequired ? !region?.id : !country?.id}
                    onClick={handleSelect}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Button
                classes={`${styles.footerButton} t-600 btn btn-uppercased`}
                onClick={handleShowActivities}
              >
                Show Activities
              </Button>
              <Button
                classes={`${styles.footerButton} t-600 btn btn-uppercased`}
                onClick={handleShowProperties}
              >
                Show Properties
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default DestinationTree;
