import React, { useCallback, useEffect, useState } from 'react';
import Select from '../../Select';
import DestinationsService from '../../../services/destinationsService';
import StorageService from '../../../services/storage.service';
import { mapperDestinationTree } from '../helper';
import { COLOR_TREE, DESTINATION_TYPE } from '../const';

const destinationsService = DestinationsService();
const storageService = StorageService();

function City({
  regionId,
  setCity,
  isLastItem = false,
  afterChange = () => {},
}) {
  const [cityList, setCityList] = useState([]);

  const loadOptionsCitiesList = useCallback(() => {
    if (regionId) {
      setCityList([]);
      destinationsService.getCities(regionId)
        .then((data) => {
          const updatedList = mapperDestinationTree(data);
          storageService.set(DESTINATION_TYPE.city, updatedList);
          return updatedList;
        })
        .then((updatedList) => {
          setCityList(updatedList);
        });
    }
  }, [setCityList, regionId]);

  useEffect(() => {
    loadOptionsCitiesList();
  }, [regionId]);

  return (
    <Select
      name="city"
      placeholder="Select a City/Level 3 (optional)"
      loadOption={loadOptionsCitiesList}
      defaultOptions={cityList}
      color={COLOR_TREE.city}
      valuePrefix="City: "
      widthContainer="90%"
      setValueState={setCity}
      isBoldStyle={isLastItem}
      afterChange={afterChange}
      withDestination
    />
  );
}

export default City;
