import React, { useCallback, useEffect, useState } from 'react';
import Select from '../../Select';
import { COLOR_TREE } from '../const';
import DestinationsService from '../../../services/destinationsService';
import { mapperDestinationTree } from '../helper';

const destinationsService = DestinationsService();

function Region({
  countryId,
  setRegion,
  isLastItem = false,
  afterChange = () => {},
}) {
  const [regionList, setRegionList] = useState([]);
  const loadOptionsRegionsList = useCallback(() => {
    if (countryId) {
      setRegionList([]);
      destinationsService.getRegions(countryId)
        .then(mapperDestinationTree)
        .then((updatedList) => {
          setRegionList(updatedList);
        });
    }
  }, [setRegionList, countryId]);

  useEffect(() => {
    loadOptionsRegionsList();
  }, [countryId]);

  return (
    <Select
      name="region"
      placeholder="Select a Region"
      loadOption={loadOptionsRegionsList}
      defaultOptions={regionList}
      color={COLOR_TREE.region}
      valuePrefix="Region: "
      widthContainer="95%"
      setValueState={setRegion}
      isBoldStyle={isLastItem}
      afterChange={afterChange}
      withDestination
    />
  );
}

export default Region;
